import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import * as apiActions from '../../../actions/api';
import * as orderActions from '../../../actions/order';
import brandEnum from '../../../enums/brandEnum';
import countryCodeEnum from '../../../enums/countryCodeEnum';
import * as selectors from '../../../sagas/selectors';

import paymentMethodShape from '../../../shapes/paymentMethodShape';

import EnvQueryComponent from '../../EnvQueryComponent';
import FontAwesome from '../../FontAwesome';
import Select from '../../Select';

import { paymentService } from '../../../services/api';

import dbsLogo from '../../../images/dbs-logo.png';
import iconAmex from './icon-amex.svg';
import iconMasterCard from './icon-master.svg';
import iconVisa from './icon-visa.svg';

import './SelectPaymentMethod.css';

function SelectPaymentMethod(props) {
  const { selectPaymentMethod, selectedPaymentMethod, defaultPaymentMethod, paymentMethods } =
    props;

  const promoCodeError = useSelector((state) => selectors.getLoyaltyError(state, 'promoCode'));
  const selectedPromoCode = useSelector(selectors.getSelectedPromoCode);
  const selectedUserRewardUuid = useSelector(selectors.getSelectedUserRewardUuid);

  useEffect(() => {
    selectPaymentMethod(defaultPaymentMethod);
  }, []);

  const handleSelect = (paymentMethodId) => {
    if (paymentMethodId !== 'add') {
      const paymentMethod = paymentMethods[paymentMethodId];
      selectPaymentMethod(paymentMethod);
    } else {
      selectPaymentMethod({ id: 'add' });
    }
  };

  const paymentMethodIcon = {
    MasterCard: iconMasterCard,
    Visa: iconVisa,
    Amex: iconAmex,
  };

  return (
    <div className="PaymentMethodContainer">
      <div className="SelectPaymentMethod">
        {selectedPaymentMethod && selectedPaymentMethod.id !== 'add' && (
          <img
            alt={selectedPaymentMethod.cardBrand}
            src={paymentMethodIcon[selectedPaymentMethod.cardBrand]}
          />
        )}
        <FontAwesome className="CardIcon" name="credit-card" />
        <Select
          className={classnames(
            'PaymentMethodList',
            { isPaymentMethodSelected: selectedPaymentMethod && selectedPaymentMethod !== null },
            { newPaymentMethod: !!selectedPaymentMethod || selectedPaymentMethod?.id === 'add' },
          )}
          value={selectedPaymentMethod?.id || 'add'}
          onChange={handleSelect}
        >
          {paymentMethods &&
            Object.values(paymentMethods).map((paymentMethod) => (
              <option key={paymentMethod.id} value={paymentMethod.id}>
                {paymentMethod?.cardBrand?.toUpperCase() ?? ''} {paymentMethod.label}
              </option>
            ))}
          <option value="add">New payment method</option>
        </Select>
      </div>
      {((selectedPromoCode && !promoCodeError) || selectedUserRewardUuid) && (
        <EnvQueryComponent
          countryCode={[countryCodeEnum.SINGAPORE]}
          brand={[brandEnum.SALADSTOP]}
          showOnMobile={false}
        >
          <div className="DbsLogoWrapper">
            <img src={dbsLogo} alt="dbs logo" className="DbsLogo" />
          </div>
        </EnvQueryComponent>
      )}
    </div>
  );
}

SelectPaymentMethod.propTypes = {
  selectedPaymentMethod: paymentMethodShape,
  selectPaymentMethod: PropTypes.func.isRequired,
  paymentMethods: PropTypes.objectOf(paymentMethodShape).isRequired,
  defaultPaymentMethod: paymentMethodShape.isRequired,
};

SelectPaymentMethod.defaultProps = {
  selectedPaymentMethod: null,
};

const mapStateToProps = (state) => ({
  paymentMethods: state.api.paymentMethods,
  selectedPaymentMethod: state.order.selectedPaymentMethod,
  defaultPaymentMethod: state.api.defaultPaymentMethod,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      withToken: apiActions.withToken,
      selectPaymentMethod: orderActions.selectPaymentMethod,
      addPaymentMethod: paymentService.addPaymentMethod.requestActionCreator,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SelectPaymentMethod);
